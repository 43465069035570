import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import { isBrowser } from "../../../context/ApolloContext"
const $ = isBrowser ? require("jquery") : {}

const CartFaqSectionWrapper = styled.div`
  width: 100%;
  position: relative;
`

const Container = styled.div`
  width: 100%;
  max-width: 1080px;
  padding: 20px;
  color: #262626;
  margin: 0px auto;
`

const CartFaqTitle = styled.h2`
  text-transform: uppercase;
  color: #262626;
  font-size: 40px;
  line-height: 1;
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-style: normal;
  margin-bottom: 10px;
  text-align: center;
  position: relative;

  @media (max-width: 600px) {
    text-align: left;
    font-size: 36px;
  }
`

const FaqWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const FaqBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #000;
  padding: 15px 12px;
  margin-bottom: 20px;
  position: relative;
  transition: all 0.3s linear;
`

const Question = styled.h3`
  display: flex;
  flex-direction: column;
  font-size: 18px;
  line-height: 1.2;
  font-family: "Gotham";
  font-weight: bold;
  padding-right: 25px;
  cursor: pointer;
  margin-bottom: 0px;
`

const Answer = styled.div`
  font-family: "Gotham Book";
  font-size: 14px;
  line-height: 18px;
  margin: 5px 0px 0px;

  ul li {
    margin-bottom: 5px;
  }

  > a {
    text-decoration: underline;
  }

  small {
    font-size: 12px;
  }
`

const OpenClose = styled.div`
  position: absolute;
  right: 12px;
  top: 15px;
  width: 25px;
  height: 25px;
  border: 1.5px solid #111;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  color: #111;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`

const CartFaqSection = ({ intl, data, title, subTitle, id }) => {
  const [active, setacitve] = useState([])
  const handleOnClickActive = i => {
    let arr = [...active]
    arr[i] = !arr[i]
    setacitve(arr)
  }

  const storeUrl = pageUrl => {
    let storePath = ""
    if (process.env.GATSBY_ACTIVE_ENV === "eur") {
      if (intl.locale === "fr") {
        storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageUrl}/`
      } else {
        storePath =
          process.env.GATSBY_ACTIVE_ENV + `/${intl.locale}/${pageUrl}/`
      }
    } else if (process.env.GATSBY_ACTIVE_ENV === "cad") {
      if (intl.locale === "en") {
        storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageUrl}/`
      } else {
        storePath =
          process.env.GATSBY_ACTIVE_ENV + `/${intl.locale}/${pageUrl}/`
      }
    } else if (process.env.GATSBY_ACTIVE_ENV === "gbp") {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageUrl}/`
    } else if (process.env.GATSBY_ACTIVE_ENV === "usd") {
      storePath = process.env.GATSBY_ACTIVE_ENV + `/${pageUrl}/`
    } else {
      if (intl.locale === "fr") {
        storePath = `${pageUrl}/`
      } else {
        storePath = `${intl.locale}/${pageUrl}/`
      }
    }
    return storePath
  }

  const handleScrollToSection = id => {
    $(document).on("click", 'a[href^="#"]', function(event) {
      if ($($.attr(this, "href")).length) {
        event.preventDefault()
        $("html, body").animate(
          {
            scrollTop: $($.attr(this, "href")).offset().top - 165,
          },
          1500
        )
      }
    })
  }

  useEffect(() => {
    isBrowser && handleScrollToSection()
    let arr = []
    data &&
      data.items.forEach((item, index) => {
        item.open || index == 0 ? arr.push(true) : arr.push(false)
      })
    setacitve(arr)
  }, [data])

  return (
    data && (
      <CartFaqSectionWrapper>
        <Container>
          {title && (
            <CartFaqTitle dangerouslySetInnerHTML={{ __html: title }} />
          )}

          <FaqWrapper>
            {data.items &&
              data.items.map((item, index) => {
                return (
                  item.question &&
                  item.answer && (
                    <FaqBox key={index.toString()}>
                      <Question
                        dangerouslySetInnerHTML={{ __html: item.question }}
                        onClick={() => handleOnClickActive(index)}
                      />
                      {active[index] && (
                        <Answer
                          dangerouslySetInnerHTML={{
                            __html: item.answer
                              .replace("shop/", storeUrl("shop"))
                              .replace("contact/", storeUrl("contact")),
                          }}
                        />
                      )}
                      <OpenClose onClick={() => handleOnClickActive(index)}>
                        {active[index] ? "-" : "+"}
                      </OpenClose>
                    </FaqBox>
                  )
                )
              })}
          </FaqWrapper>
        </Container>
      </CartFaqSectionWrapper>
    )
  )
}

export default injectIntl(CartFaqSection)
