export const AbandonedCartMail = (cartArr, email, lang, pageUrl) =>
  new Promise((resolve, reject) => {
    // const baseUrl = "http://localhost:3000/emailsend"
    const baseUrl =
      "https://us-central1-percko-website.cloudfunctions.net/abandonedCartMail"
    fetch(baseUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        cart: cartArr,
        email: email,
        language: lang,
        cartPageUrl: pageUrl,
      }),
    })
      .then(response => response.json())
      .then(res => {
        resolve(res)
      })
      .catch(err => {
        console.log(err)
        reject(err)
      })
  })
