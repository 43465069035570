import React from "react"
import styled from "@emotion/styled"
import { injectIntl } from "gatsby-plugin-intl"
import ReactTooltip from "react-tooltip"

const TestimonialsWrapper = styled.div`
  display: flex;
  background: #e3f1ef;
  width: 100%;
  align-items: center;
`

const TestimonialsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px auto;
  padding: 15px 20px;
  justify-content: space-between;

  @media (max-width: 767px) {
    flex-wrap: wrap;
  }

  @media (max-width: 440px) {
    flex-wrap: wrap;
  }

  .__react_component_tooltip {
    background: #fff;
    color: #262626;
    max-width: 300px;
    border-radius: 40px 0px;
    border-width: 1px;
    border-color: #5db9bc;
    padding: 20px;
  }

  .__react_component_tooltip h6 {
    margin: 0px 0px 10px 0px;
    font-family: "BebasNeueBold";
    font-size: 24px;
  }

  .__react_component_tooltip p {
    margin: 0px;
    font-family: "Gotham Book";
    font-size: 14px;
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
  .__react_component_tooltip.place-left:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
    border-left-color: #fff;
    border-left-style: solid;
    border-left-width: 6px;
  }
  .__react_component_tooltip.place-right:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -6px;
    top: 50%;
    margin-top: -4px;
    border-right-color: #fff;
    border-right-style: solid;
    border-right-width: 6px;
  }
  .__react_component_tooltip.place-top:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top-color: #fff;
    border-top-style: solid;
    border-top-width: 6px;
  }
  .__react_component_tooltip.place-bottom:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: #fff;
    border-bottom-style: solid;
    border-bottom-width: 6px;
  }
`

const TestimonialTitle = styled.div`
  font-family: "BebasNeueBold";
  font-weight: bold;
  font-size: 32px;
  line-height: 1;
  margin-bottom: 10px;
  text-align: center;

  @media (max-width: 550px) {
    font-size: 24px;
  }
`

const TestimonialSubtitle = styled.div`
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;

  @media (max-width: 350px) {
    font-size: 14px;
  }
`

const Testimonials = styled.div`
  display: flex;
  width: 100%;
  max-width: 750px;
  justify-content: center;
  align-items: center;
  margin: 15px 0px 0px 0px;
`

const TestimonialBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    flex-direction: column;
  }

  .__react_component_tooltip {
    background: #fff;
    color: #262626;
    max-width: 300px;
    border-radius: 40px 0px;
    border-width: 1px;
    border-color: #5db9bc;
    padding: 20px;
  }

  .__react_component_tooltip h6 {
    margin: 0px 0px 10px 0px;
    font-family: "BebasNeueBold";
    font-size: 24px;
  }

  .__react_component_tooltip p {
    margin: 0px;
    font-family: "Gotham Book";
    font-size: 14px;
  }

  .__react_component_tooltip.show {
    opacity: 1;
  }
  .__react_component_tooltip.place-left:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    right: -6px;
    top: 50%;
    margin-top: -4px;
    border-left-color: #fff;
    border-left-style: solid;
    border-left-width: 6px;
  }
  .__react_component_tooltip.place-right:after {
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    left: -6px;
    top: 50%;
    margin-top: -4px;
    border-right-color: #fff;
    border-right-style: solid;
    border-right-width: 6px;
  }
  .__react_component_tooltip.place-top:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
    border-top-color: #fff;
    border-top-style: solid;
    border-top-width: 6px;
  }
  .__react_component_tooltip.place-bottom:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    top: -6px;
    left: 50%;
    margin-left: -8px;
    border-bottom-color: #fff;
    border-bottom-style: solid;
    border-bottom-width: 6px;
  }
`

const TestimonialImage = styled.img`
  width: 100%;
  max-width: ${({ order }) => (order == 1 ? "25px" : "30px")};
  margin: 0px 7px 0px 0px;
  object-fit: contain;
  cursor: pointer;

  @media (max-width: 600px) {
    margin: 0px 0px 7px 0px;
  }
`

const DesktopTestimonialText = styled.div`
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
  text-decoration: underline;
  text-decoration-color: #262626;

  @media (max-width: 767px) {
    display: none;
  }
`

const MobileTestimonialText = styled.div`
  font-family: "Gotham Book";
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2;
  text-align: center;
  cursor: pointer;
  display: none;
  text-decoration: underline;
  text-decoration-color: #262626;

  @media (max-width: 767px) {
    display: block;
  }

  @media (max-width: 400px) {
    font-size: 14px;
  }

  @media (max-width: 360px) {
    font-size: 13px;
  }
`

const CartTestimonialSection = ({ intl, data }) => {
  return (
    data && (
      <TestimonialsWrapper>
        {data.list && (
          <TestimonialsContainer>
            {data.title && (
              <TestimonialTitle
                dangerouslySetInnerHTML={{
                  __html: data.title,
                }}
              />
            )}
            {data.subtitle && (
              <TestimonialSubtitle
                dangerouslySetInnerHTML={{
                  __html: data.subtitle,
                }}
              />
            )}
            <Testimonials>
              {data.list.map((item, index) => {
                return (
                  item && (
                    <TestimonialBox key={index.toString()}>
                      {item.image && (
                        <TestimonialImage
                          className="lazyload"
                          order={index}
                          data-src={item.image}
                          data-tip={item.popup_text}
                          data-html={true}
                          data-for={`testimonial_section` + index}
                        />
                      )}
                      {item.text && (
                        <DesktopTestimonialText
                          dangerouslySetInnerHTML={{
                            __html: item.text,
                          }}
                          data-tip={item.popup_text}
                          data-html={true}
                          data-for={`testimonial_section` + index}
                        />
                      )}
                      {item.mobile_text && (
                        <MobileTestimonialText
                          lang={intl.locale}
                          dangerouslySetInnerHTML={{
                            __html: item.mobile_text,
                          }}
                          data-tip={item.popup_text}
                          data-html={true}
                          data-for={`testimonial_section` + index}
                        />
                      )}
                      <ReactTooltip
                        id={`testimonial_section` + index}
                        html={true}
                        place={index == 0 ? "right" : "top"}
                      />
                    </TestimonialBox>
                  )
                )
              })}
            </Testimonials>
          </TestimonialsContainer>
        )}
      </TestimonialsWrapper>
    )
  )
}

export default injectIntl(CartTestimonialSection)
